/* ==========================================================================
   Responsive Embed
   Credit: Nicolas Gallagher and SUIT CSS.

   <!-- 16:9 aspect ratio -->
   <div class="responsive-embed responsive-embed-16by9">
     <iframe class="responsive-embed-item" src="..."></iframe>
   </div>

   <!-- 4:3 aspect ratio -->
   <div class="responsive-embed responsive-embed-4by3">
     <iframe class="responsive-embed-item" src="..."></iframe>
   </div>
   ========================================================================== */

.responsive-embed {
  display: block;
  position: relative;
  width: 100%;
  margin-bottom: 1.5rem;
  padding: 0;
  overflow: hidden;

  &::before {
    display: block;
    content: "";
  }

  .responsive-embed-item,
  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.responsive-embed-21by9 {
  &::before {
    padding-top: percentage(9 / 21);
  }
}

.responsive-embed-16by9 {
  &::before {
    padding-top: percentage(9 / 16);
  }
}

.responsive-embed-4by3 {
  &::before {
    padding-top: percentage(3 / 4);
  }
}

.responsive-embed-1by1 {
  &::before {
    padding-top: percentage(1 / 1);
  }
}
