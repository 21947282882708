/* ==========================================================================
   Syntax highlighting
   ========================================================================== */

/* Inline code block */

code.highlighter-rouge {
  margin: 0;
  padding: 0.2em 0.4em;
  font-size: 85%;
  border-radius: 3px;
}

/* Multi-line code blocks */

div.highlighter-rouge,
figure.highlight {
  position: relative;
  margin-bottom: 1em;
  font-family: $monospace-font-family;
  line-height: 1.8;

  > pre,
  pre.highlight {
    margin: 0;
    padding: 1em;
  }
}

.highlight table {
  margin-bottom: 0;
  font-size: 1rem;
  border: 0;

  td {
    padding: 0;
    width: calc(100% - 1em);
    border: 0;

    /* line numbers*/
    &.gutter {
      padding-right: 1em;
      width: 1em;
      border-right: 1px solid;
      text-align: right;
    }

    /* code */
    &.code {
      padding-left: 1em;
    }
  }

  pre {
    margin: 0;
  }
}

.highlight pre {
  width: 100%;
}

.gist {
  td,
  th {
    border-bottom: 0;
  }
}
