//////////////////////////////
// Import Pieces
//////////////////////////////
@import "double/default-pair";
@import "double/double-string";
@import "double/default";

@function breakpoint-parse-double($feature, $empty-media, $first) {
  $parsed: '';
  $leader: '';
  // If we're forcing
  @if not ($empty-media) or not ($first) {
    $leader: 'and ';
  }

  $first: nth($feature, 1);
  $second: nth($feature, 2);

  // If we've got two numbers, we know we need to use the default pair because there are no media queries that has a media feature that is a number
  @if type-of($first) == 'number' and type-of($second) == 'number' {
    $parsed: breakpoint-parse-default-pair($first, $second);
  }
  // If they are both strings, we send it through the string parser
  @else if type-of($first) == 'string' and type-of($second) == 'string' {
    $parsed: breakpoint-parse-double-string($first, $second);
  }
  // If it's a string/number pair, we parse it as a normal double
  @else {
    $parsed: breakpoint-parse-double-default($first, $second);
  }

  @return $leader + $parsed;
}
