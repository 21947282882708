/* ==========================================================================
   Author
   ========================================================================== */

.author-avatar {
  margin-right: 1em;
  width: 50px;
  height: auto;
  border-radius: 50%;

  @include breakpoint($large) {
    margin-right: 0;
    margin-bottom: 1em;
    width: 100px;
    height: auto;
  }
}

.author-name {
  @include breakpoint($large) {
    margin-bottom: 0.5em;
  }
}

.author-links {
  display: none;
  margin: 0 0 1.5em;
  padding: 0;
  list-style: none;

  @include breakpoint($large) {
    display: block;
  }
}

.author-link {
  display: inline;
  padding-right: 0.5em;

  @include breakpoint($large) {
    padding: 0.25em;
  }

  a {
    text-decoration: none;
  }
}
