/* ==========================================================================
   NOTICE TEXT BLOCKS
   ========================================================================== */

/**
 *  Default Kramdown usage (no indents!):
 *  <div class="notice" markdown="1">
 *  #### Headline for the Notice
 *  Text for the notice
 *  </div>
 */

@mixin notice($notice-color) {
  margin: 2em 0 !important; /* override*/
  padding: 1em;
  @include yiq-contrasted($notice-color);
  border-radius: $border-radius;
  -webkit-box-shadow: 0 1px 1px rgba($notice-color, 0.25);
  box-shadow: 0 1px 1px rgba($notice-color, 0.25);

  h4 {
    margin-top: 0 !important; /* override*/
    margin-bottom: 0.75em;
  }

  @at-root .page__content #{&} h4 {
    /* using at-root to override .page-content h4 font size*/
    margin-bottom: 0;
    font-size: 1rem;
  }

  p {
    &:last-child {
      margin-bottom: 0 !important; /* override*/
    }
  }

  h4 + p {
    /* remove space above paragraphs that appear directly after notice headline*/
    margin-top: 0;
    padding-top: 0;
  }

  a {
    color: mix(#000, $notice-color, 75%);

    &:hover {
      color: mix(#000, $notice-color, 60%);
    }
  }

  ul {
    &:last-child {
      margin-bottom: 0; /* override*/
    }
  }
}
