/* ==========================================================================
   BUTTONS
   ========================================================================== */

/*
   Default button
   ========================================================================== */

.btn {
  /* default */
  display: inline-block;
  margin-bottom: 0.25em;
  padding: 0.5em 1em;
  font-family: $sans-serif-font-family;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border-width: 0;
  border-radius: $border-radius;
  cursor: pointer;

  .icon {
    margin-right: 0.5em;
  }

  .icon + .hidden {
    margin-left: -0.5em; /* override for hidden text*/
  }

  /* fills width of parent container */
  &--block {
    display: block;
    width: 100%;

    + .btn--block {
      margin-top: 0.25em;
    }
  }

  /* disabled */
  &--disabled {
    pointer-events: none;
    cursor: not-allowed;
    filter: alpha(opacity=65);
    box-shadow: none;
    opacity: 0.65;
  }

  /* extra large button */
  &--x-large {
    font-size: 2rem;
  }

  /* large button */
  &--large {
    font-size: 1.5rem;
  }

  /* small button */
  &--small {
    font-size: 0.75rem;
  }
}
