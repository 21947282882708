@function breakpoint-no-query($query) {
  @if type-of($query) == 'list' {
    $keyword: nth($query, 1);

    @if type-of($keyword) == 'string' and ($keyword == 'no-query' or $keyword == 'no query' or $keyword == 'fallback') {
      @return nth($query, 2);
    }
    @else {
      @return false;
    }
  }
  @else {
    @return false;
  }
}
