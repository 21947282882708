/* ==========================================================================
   Tables
   ========================================================================== */

table {
  @include fluid-type($min-vw, $max-vw, 12px, 18px);
  width: 100%;
  border-collapse: collapse;
  /* For Firefox to horizontally scroll wider tables */
  // scss-lint:disable DuplicateProperty
  word-break: normal;
  word-break: keep-all;
}

th,
td {
  border-bottom: 1px solid;
}

th {
  padding: 0.5em;
  font-weight: bold;
  text-align: left;
}

td {
  padding: 0.5em;
}

tr,
td,
th {
  vertical-align: middle;
}
